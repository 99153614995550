import BaseRenderMixin from "@/components/base/BaseRenderMixin";
import {is_empty} from "../utils";

export default {
    name: "BaseFilterPriceItemComponent",
    mixins: [BaseRenderMixin],
    methods: {
        make_element_tree(createElement, element_data, options = {},) {
            let data = {}
            let el, props, attrs, style, children

            [el, props] = this.get_el_props(element_data)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) {
                data.attrs = attrs
                if ('deepsel-filter-submit-filter' in attrs) data.on = {click: this.on_click}
            }
            if (style) data.style = style

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_children(element_data, createElement) {
            let children = []
            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {
                        children.push(this.make_element_tree(createElement, item));
                    } else if (item.node === "text") {
                        children.push(item.text)
                    }
                }
            }
            return children
        },
        on_click() {
            let slider = window.$(this.$el).find("*[deepsel-filter-slider-price-item]")

            //store applied filter in url hash
            let url_object = new URL(document.URL)
            let hash = url_object.hash.substr(1)
            let filter_object = {}
            if (!is_empty(hash)) {
                filter_object = hash
                    .split('&')
                    .reduce((result, item) => {
                        let parts = item.split('=')
                        result[parts[0]] = parts[1]
                        return result
                    }, {});
            }
            filter_object['price_min'] = slider.slider("values", 0 )
            filter_object['price_max'] = slider.slider("values", 1 )

            url_object.hash = `#${new URLSearchParams(filter_object).toString()}`
            window.location.href = url_object.href
        },
    }
}
